import React, { useEffect, useState } from "react";
import { graphql, StaticQuery } from "gatsby"
import PropTypes from "prop-types";

import Layout from "../components/layout";
import SEO from "../components/seo";

import SmallHero from '../components/elements/small-hero/small-hero';
import BookCta from '../components/elements/book-cta/book-cta';
import TwoColFullScreen from "../components/layouts/2-col-full-screen/2-col-full-screen";
import ServiceInfo from "../components/services/service-info/service-info";
const VIPListPage = ({ data }) => {

  let [loaded, setLoaded] = useState(true)

  useEffect(() => {
    setLoaded(true)
  }, [])

  return (
    <Layout>
      <SEO
        title="Subscriptions"
      />
      <SmallHero 
        loaded={loaded}
        bgImage={data.herofull.childImageSharp.fluid}
        title='Subscriptions'
      />
      <TwoColFullScreen cols={[
        {
          bg: 'text-white bg-primary-darker',
          content: <ServiceInfo 
              key={1}
              title="Aesthetics Club - Filler"
              priceList={[
                {
                  price: '£66.66 a month',
                  text: '6 months 2x.5ml',
                  padding: 'pr-6'
                }
              ]}
              smallPrice
              alignPrice="bottom"
              lowerText={<div>
                <a className="text-primary underline" href="https://pay.gocardless.com/AL0014TK22FZ1M" rel="noreferrer" target="_blank">
                    https://pay.gocardless.com/AL0014TK22FZ1M
                </a>
              </div>
            }
        />
        },
        {
          bg: 'bg-white',
          content: <ServiceInfo 
            key={1}
            title="Aesthetics Club – Profhilo Double New User"
            priceList={[
                {
                price: '£91.66 a month',
                text: '6 payments',
                padding: 'pr-6'
                }
            ]}
            smallPrice
            alignPrice="bottom"
            lowerText={<div>
                <a className="text-primary underline" href="https://pay.gocardless.com/AL0013MZJH1C9F" rel="noreferrer" target="_blank">
                https://pay.gocardless.com/AL0013MZJH1C9F
                </a>
            </div>
            }
        />
        },
        {
            bg: 'bg-primary-darker text-white md:text-black md:bg-white',
            content: <ServiceInfo 
            key={1}
            title="Aesthetics Club – Profhilo Double and Single Top Up New User"
            priceList={[
                {
                price: '£72.92 a month',
                text: '12 payments',
                padding: 'pr-6'
                }
            ]}
            smallPrice
            alignPrice="bottom"
            lowerText={<div>
                <a className="text-primary underline" href="https://pay.gocardless.com/AL0013NGMWTK8N" rel="noreferrer" target="_blank">
                https://pay.gocardless.com/AL0013NGMWTK8N
                </a>
            </div>
            }
        />
        },
        {
          bg: 'bg-white md:text-white md:bg-primary-darker',
          content: <ServiceInfo 
            key={1}
            title="Aesthetics Club – Profhilo Single – Current Users of Profhilo ONLY"
            priceList={[
                {
                text: ' 6 Payments',
                padding: 'pr-6'
                }
            ]}
            smallPrice
            alignPrice="bottom"
            lowerText={<div>
                <a className="text-primary underline" href="https://pay.gocardless.com/AL0013MZ0F3W1Z" rel="noreferrer" target="_blank">
                https://pay.gocardless.com/AL0013MZ0F3W1Z
                </a>
            </div>
            }
        />
        },
        {
            bg: 'text-white bg-primary-darker',
            content: <ServiceInfo 
                key={1}
                title="Botox Club – 2 Areas for the year "
                priceList={[
                  {
                    price: '£70 per month',
                    text: '12 Payments',
                    padding: 'pr-6'
                  }
                ]}
                smallPrice
                alignPrice="bottom"
                lowerText={<div>
                  <a className="text-primary underline" href="https://pay.gocardless.com/AL000K6VZ779MQ" rel="noreferrer" target="_blank">
                  https://pay.gocardless.com/AL000K6VZ779MQ
                  </a>
                </div>
              }
          />
          },
          {
            bg: 'bg-white',
            content: <ServiceInfo 
              key={1}
              title="Botox Club – 3 Areas for the year"
              priceList={[
                  {
                  price: '£80 per month',
                  text: '12 Payments',
                  padding: 'pr-6'
                  }
              ]}
              smallPrice
              alignPrice="bottom"
              lowerText={<div>
                  <a className="text-primary underline" href="https://pay.gocardless.com/AL000K6TWCY1MG" rel="noreferrer" target="_blank">
                  https://pay.gocardless.com/AL000K6TWCY1MG
                  </a>
              </div>
              }
          />
          },
          {
            bg: 'bg-primary-darker text-white md:text-black md:bg-white',
            content: <ServiceInfo 
            key={1}
            title="Laser Hair Removal 3 Areas T’s & C’s apply*"
            priceList={[
                {
                price: '£125 per month',
                text: '12 payments',
                padding: 'pr-6'
                }
            ]}
            smallPrice
            alignPrice="bottom"
            lowerText={<div>
                <a className="text-primary underline" href="https://pay.gocardless.com/AL000K759PNKJB" rel="noreferrer" target="_blank">
                https://pay.gocardless.com/AL000K759PNKJB
                </a>
            </div>
            }
        />
        },
        {
          bg: 'bg-white md:text-white md:bg-primary-darker',
          content: <ServiceInfo 
            key={1}
            title="Forma Course of 6 – Face Only"
            priceList={[
                {
                price: '£68.75 per month',
                text: '12 Payments',
                padding: 'pr-6'
                }
            ]}
            smallPrice
            alignPrice="bottom"
            lowerText={<div>
                <a className="text-primary underline" href="https://pay.gocardless.com/AL000VRWM7NE3C" rel="noreferrer" target="_blank">
                https://pay.gocardless.com/AL000VRWM7NE3C
                </a>
            </div>
            }
        />
        },
        {
            bg: 'text-white bg-primary-darker',
            content: <ServiceInfo 
                key={1}
                title="Forma Course of 6 – Face and Neck or Body "
                priceList={[
                  {
                    price: '£91.67 per month',
                    text: '12 Payments',
                    padding: 'pr-6'
                  }
                ]}
                smallPrice
                alignPrice="bottom"
                lowerText={<div>
                  <a className="text-primary underline" href="https://pay.gocardless.com/AL000VRWCRRFG8" rel="noreferrer" target="_blank">
                  https://pay.gocardless.com/AL000VRWCRRFG8
                  </a>
                </div>
              }
          />
          },
          {
            bg: 'bg-white',
            content: <ServiceInfo 
              key={1}
              title="Morpheus 8 Complete Package"
              priceList={[
                  {
                  price: '£183.33 per month',
                  text: '£500 deposit followed by 12 Payments ',
                  padding: 'pr-6'
                  }
              ]}
              smallPrice
              alignPrice="bottom"
              lowerText={<div>
                  <a className="text-primary underline" href="https://pay.gocardless.com/AL000K4PXJQFD2" rel="noreferrer" target="_blank">
                  https://pay.gocardless.com/AL000K4PXJQFD2
                  </a>
              </div>
              }
          />
          },
          {
            bg: 'bg-primary-darker text-white md:text-black md:bg-white',
            content: <ServiceInfo 
            key={1}
            title="Mini Morpheus8 - Course of 3 "
            priceList={[
                {
                price: ' £100 per month',
                text: '12 payments',
                padding: 'pr-6'
                }
            ]}
            smallPrice
            alignPrice="bottom"
            lowerText={<div>
                <a className="text-primary underline" href="https://pay.gocardless.com/AL000VRW4QQJD9" rel="noreferrer" target="_blank">
                https://pay.gocardless.com/AL000VRW4QQJD9
                </a>
            </div>
            }
        />
        },
        {
          bg: 'bg-white md:text-white md:bg-primary-darker',
          content: <ServiceInfo 
            key={1}
            title="PMU Single Top Up – Brows, Lips, or Eyeliner – Treatment every 18 months"
            priceList={[
                {
                price: '£11.11 per month',
                text: '18 Payments',
                padding: 'pr-6'
                }
            ]}
            smallPrice
            alignPrice="bottom"
            lowerText={<div>
                <a className="text-primary underline" href="https://pay.gocardless.com/AL000K6K6SKH6T" rel="noreferrer" target="_blank">
                https://pay.gocardless.com/AL000K6K6SKH6T
                </a>
            </div>
            }
        />
        },
        {
            bg: 'text-white bg-primary-darker',
            content: <ServiceInfo 
                key={1}
                title="PMU Single Top Up – Brows, Lips, or Eyeliner – Annually"
                priceList={[
                  {
                    price: '£16.66 per Month',
                    text: '12 Payments',
                    padding: 'pr-6'
                  }
                ]}
                smallPrice
                alignPrice="bottom"
                lowerText={<div>
                  <a className="text-primary underline" href="https://pay.gocardless.com/AL000K6JVZCNTT" rel="noreferrer" target="_blank">
                  https://pay.gocardless.com/AL000K6JVZCNTT
                  </a>
                </div>
              }
          />
          },
          {
            bg: 'bg-white',
            content: <ServiceInfo 
              key={1}
              title="Carboxy Therapy – Course of 6"
              priceList={[
                  {
                  price: '£41.25 per month ',
                  text: '12 Payments',
                  padding: 'pr-6'
                  }
              ]}
              smallPrice
              alignPrice="bottom"
              lowerText={<div>
                  <a className="text-primary underline" href="https://pay.gocardless.com/AL000VRWYTZQDG" rel="noreferrer" target="_blank">
                  https://pay.gocardless.com/AL000VRWYTZQDG
                  </a>
              </div>
              }
          />
        },
        {
            bg: 'bg-primary-darker text-white md:text-black md:bg-white',
            content: <ServiceInfo 
                key={1}
                title="Scalp PRP Therapy for Thinning Hair – Course Of 3"
                priceList={[
                  {
                    price: '£62.50 per Month',
                    text: '12 Payments',
                    padding: 'pr-6'
                  }
                ]}
                smallPrice
                alignPrice="bottom"
                lowerText={<div>
                  <a className="text-primary underline" href="https://pay.gocardless.com/AL000VRX2YQZDX" rel="noreferrer" target="_blank">
                  https://pay.gocardless.com/AL000VRX2YQZDX
                  </a>
                </div>
              }
          />
          },
          {
            bg: 'bg-white md:text-white md:bg-primary-darker',
            content: <ServiceInfo 
                key={1}
                smallPrice
                alignPrice="bottom"
                lowerText={<div>
                    <p rel="noreferrer" target="_blank">
                    Our monthly interest free subscriptions for your luxury treatments.
                    <br/><br/>
                    These subscriptions are available immediately by following the link below each option.  Once your subscription has been submitted our reception team will be in touch to arrange all your appointments and answer questions you have.
                    <br/><br/>
                    If you would like to talk about a subscription for a treatment not listed, please contact us.
                    </p>
                </div>
                }
            />
        },
        ]}/>
      <BookCta discount="https://gift-cards.phorest.com/salons/houseofpureltd" link="https://phorest.com/book/salons/houseofpureltd" phone="01353 661 321" email="info@houseofpure.co.uk" bg={data.bookbg.childImageSharp.fluid} />
    </Layout>
  );
}

VIPListPage.propTypes = {
  data: PropTypes.object,
};

const indexQuery = graphql`
  query {
    herofull: file(relativePath: { eq: "main-bg.jpg" }) {
      childImageSharp {
        fluid(quality: 50, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    bookbg: file(relativePath: { eq: "booking-bg-blurry.jpg" }) {
      childImageSharp {
        fluid(quality: 50, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
const VIPListComponent = props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <VIPListPage props data={data} {...props} />
    )}
  />
)

VIPListComponent.displayName = "VIPListComponent"

export default VIPListComponent
